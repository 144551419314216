import { getAuthorizedApi } from "api/apiInstance";
import { baseUrl } from "api/constants";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getTokenFromCookies } from "constants/loginConstants";
import {
  OPINIONDATA_GET_INIT,
  OPINIONDATA_GET_SUCCESS,
  OPINIONDATA_GET_FAILED,
  PREVIOUS_POLL_INIT,
  PREVIOUS_POLL_SUCCESS,
  PREVIOUS_POLL_FAILED,
  POST_COMMENT_SUCCESS,
  POST_COMMENT_FAILED,
  POST_COMMENT_INIT,
  GET_POST_COMMENT_INIT,
  GET_POST_COMMENT_SUCCESS,
  GET_POST_COMMENT_FAILED,
  SAVE_POST_POLL_SUCCESS,
  UPDATE_COMMENT_INIT,
  DELETE_COMMENT_INIT,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILED,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAILED,
  POST_UPDATE_COMMENT_SUCCESS,
  POST_UPDATE_COMMENT_FAILED,
  POST_UPDATE_COMMENT_INIT,
  GET_POLLS_INIT,
  GET_POLLS_FETCHED,
} from "redux/actionTypes/opinion.actionTypes";
import { decryptData } from "utils/encryption";
import { logger } from "logging/log-util";
// import {
//   REFRESH_PAGE_INIT,
//   SHOW_MODAL_POPUP,
// } from "../actionTypes/common.actionTypes";

function* OpinionData({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json());
  // /*.then(({ encryptedToken }) => encryptedToken || "")*/ console.log(
  //   " ******** ",
  //   token
  // );
  // /*.then(({ encryptedToken }) => encryptedToken || "")*/ /* .then(({ encryptedToken }) => encryptedToken || "");*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.post,
      `${baseUrl}/poll/save-user-response`,
      payload
    );
    if (data.status) {
      // localStorage.setItem("userToken", data.data?.userToken);
      yield put({ type: GET_POLLS_INIT });
      yield put({
        type: OPINIONDATA_GET_SUCCESS,
        payload: {
          entity: data?.result,
        },
      });
      yield put({
        type: SAVE_POST_POLL_SUCCESS,
        payload: {
          pollResponse: data?.result,
        },
      });
      // yield put({
      //   type: PREVIOUS_POLL_INIT,
      //   payload: { limit: 50 },
      // });
    } else {
      yield put({
        type: OPINIONDATA_GET_FAILED,
        payload: {
          error: true,
          message: data?.message || "Error Occured",
        },
      });
    }
  } catch (e) {
    logger.error(e);
    // yield put({
    //   type: SHOW_MODAL_POPUP,
    //   payload: {
    //     error: true,
    //     message: new Error(e).message,
    //   },
    // });
  }
}

function* GetPreviousPolls({ payload: { limit } }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json());
  // /* .then(({ encryptedToken }) => encryptedToken || "");*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  // .then(({ encryptedToken }) => encryptedToken || "");
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.get,
      `${baseUrl}/poll/previous-polls?limit=${limit}&skip=1`
    );

    if (data.status) {
      yield put({
        type: PREVIOUS_POLL_SUCCESS,
        payload: {
          previousPolls: data?.result?.polls,
        },
      });
    } else {
      yield put({
        type: PREVIOUS_POLL_FAILED,
      });
    }
  } catch (e) {
    logger.error(e);
    // yield put({
    //   type: SHOW_MODAL_POPUP,
    //   payload: {
    //     error: true,
    //     message: new Error(e).message,
    //   },
    // });
  }
}

function* GetCommentByPosts({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json());
  // // .then(({ encryptedToken }) => encryptedToken || "");
  // /* .then(({ encryptedToken }) => encryptedToken || "");*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.get,
      `${baseUrl}/comment/post-comments-new?postId=${payload?.postId}&table=post&limit=5&skip=0`
    );
    console.log(
      "testing comment api",
      `${baseUrl}/comment/post-comments-new?postId=${payload?.postId}&table=post&limit=5&skip=0`
    );

    if (data.status) {
      console.log("payload?.index", payload?.index);
      yield put({
        type: GET_POST_COMMENT_SUCCESS,
        payload: { commentsData: data.result.comments, index: payload?.index },
      });
    } else {
      yield put({
        type: GET_POST_COMMENT_FAILED,
      });
    }
  } catch (e) {
    logger.error(e);
    // yield put({
    //   type: SHOW_MODAL_POPUP,
    //   payload: {
    //     error: true,
    //     message: new Error(e).message,
    //   },
    // });
  }
}

function* PostCommentByPostId({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json());
  // // .then(({ encryptedToken }) => encryptedToken || "");
  // /* .then(({ encryptedToken }) => encryptedToken || "");*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(client.post, `${baseUrl}/comment`, payload);

    if (data.status) {
      yield put({
        type: POST_COMMENT_SUCCESS,
      });
      yield put({
        type: GET_POST_COMMENT_INIT,
        payload: { postId: payload.postId, index: payload?.index },
      });
    } else {
      yield put({
        type: POST_COMMENT_FAILED,
      });
    }
  } catch (e) {
    logger.error(e);
  }
}
function* UpdatePostComment({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json());
  // /* .then(({ encryptedToken }) => encryptedToken || "");*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  try {
    const { data } = yield call(
      client.post,
      `${baseUrl}/comment/comment-count`,
      payload
    );

    if (data.status) {
      yield put({
        type: POST_UPDATE_COMMENT_SUCCESS,
        payload: {
          entity: data?.result,
        },
      });
      // yield put({
      //   type: GET_POST_COMMENT_INIT,
      //   payload: { postId: payload.postId },
      // });
    } else {
      yield put({
        type: POST_UPDATE_COMMENT_FAILED,
      });
    }
  } catch (e) {
    logger.error(e);
  }
}

function* commentEdit({ payload }) {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json()); /*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);
  const { postId, ...postpayload } = payload;
  try {
    const { data } = yield call(client.put, `${baseUrl}/comment`, postpayload);

    if (data.status) {
      yield put({
        type: UPDATE_COMMENT_SUCCESS,
      });
      yield put({
        type: GET_POST_COMMENT_INIT,
        payload: { postId: payload.postId, index: payload?.index },
      });
    } else {
      yield put({
        type: UPDATE_COMMENT_FAILED,
      });
    }
  } catch (e) {
    logger.error(e);
  }
}

function* commentDelete({ payload }) {
  // const client = yield getAuthorizedApi(token);
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json()); /*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const accessToken = yield decryptData(token);
  const { postId, ...postpayload } = payload;
  const data = yield fetch(`${baseUrl}/comment`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(postpayload),
  }).then((res) => res.json());
  if (data.status) {
    yield put({
      type: DELETE_COMMENT_SUCCESS,
    });
    yield put({
      type: GET_POST_COMMENT_INIT,
      payload: { postId: payload.postId, index: payload?.index },
    });
  } else {
    yield put({
      type: DELETE_COMMENT_FAILED,
    });
  }
}

function* GetPolls() {
  // let token = yield fetch("/api/getToken", {
  //   method: "get",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((res) => res.json()); /*.then(({
  //   encryptedToken
  // }) => encryptedToken || "")*/
  // if (token && typeof token === "string") {
  //   token = JSON.parse(token);
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // } else if (typeof token === "object") {
  //   if (token.encryptedToken) {
  //     token = token.encryptedToken;
  //   }
  // }
  const token = getTokenFromCookies(document.cookie);
  const client = yield getAuthorizedApi(token);

  const { data } = yield call(
    client.get,
    `${baseUrl}/poll/all-polls?limit=10&skip=1`
  );
  yield put({
    type: GET_POLLS_FETCHED,
    payload: {
      pollsData: data?.result,
    },
  });
}

function* opinionSaga() {
  yield takeLatest(OPINIONDATA_GET_INIT, OpinionData);
  yield takeEvery(PREVIOUS_POLL_INIT, GetPreviousPolls);
  yield takeEvery(POST_COMMENT_INIT, PostCommentByPostId);
  yield takeEvery(GET_POST_COMMENT_INIT, GetCommentByPosts);
  yield takeEvery(UPDATE_COMMENT_INIT, commentEdit);
  yield takeEvery(DELETE_COMMENT_INIT, commentDelete);
  yield takeEvery(POST_UPDATE_COMMENT_INIT, UpdatePostComment);
  yield takeEvery(GET_POLLS_INIT, GetPolls);
}

export default opinionSaga;
